import { createContext, useState, useContext } from "react";
import { useCookies } from "react-cookie";

const AppContext = createContext(null);

const AppProvider = ({ children }) => {
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies([ "session", "tenant", "email", "sessionExpire" ]);
  const [messageData, setMessageData] = useState({});
  const [scripterData, setScripterData] = useState({});
  const [creditCardInfoData, setCreditCardInfoData] = useState({});
  // const [creditCardStatusData, setCreditCardStatusData] = useState({});
  const [activecallkeyData, setActivecallkeyData] = useState("");
  const [isVisibleIntTable, setIsVisibleIntTable] = useState(false);
  const [dtmfState, setDtmfState] = useState({
    open: false,
    ack: null,
    status: null,
    statusText: "",
    data: [],
    selectedInput: null,
  });
  const [openDTMFModal, setOpenDTMFModal] = useState(false);
  const [showDTMFButton, setShowDTMFButton] = useState(false);
  const [franchiseName, setFranchiseName] = useState("Dominos");


  return (
    <AppContext.Provider
      value={{
        isLoadingPage,
        setIsLoadingPage,
        isAuth,
        setIsAuth,
        cookies,
        setCookie,
        removeCookie,
        messageData,
        setMessageData,
        scripterData,
        setScripterData,
        // creditCardStatusData,
        // setCreditCardStatusData,
        creditCardInfoData,
        setCreditCardInfoData,
        activecallkeyData,
        setActivecallkeyData,
        isVisibleIntTable, 
        setIsVisibleIntTable,
        dtmfState,
        setDtmfState,
        openDTMFModal, 
        setOpenDTMFModal,
        showDTMFButton, 
        setShowDTMFButton,
        franchiseName, 
        setFranchiseName
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
